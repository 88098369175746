<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'

export default {
  name: "LeaveBehindAds",

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)

      window.MediaAlphaExchange = {
        data: {
          zip: store.state.fields['zip']
        },
        placement_id: 'kiBiLv_VOJeOuXXipQtjL3oSmHA0GA',
        sub_1: store.state.fields['affid'],
        sub_2: store.state.fields['transaction_id'],
        sub_3: 45,
        type: 'ad_unit',
        version: 17
        //TODO: Get accurate values for media alpha exchange, these are ripped as defaults from AmericanFirstHealth
      }

      window.MediaAlphaExchange__load('ad_unit_placeholder')
    })

  }
}
</script>

<template>
  <div class="container media-alpha-share-container">
    <div class="row">
      <div class="box">
        <h2 class="media-alpha-title">Your Quotes are Ready!</h2>
        <h3 class="media-alpha-subtitle">Click on a few options to compare rates:</h3>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <div id="ad_unit_placeholder"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.main-container {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.media-alpha-share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.media-alpha-title {
  font-family: sans-serif;
  font-size: 32px;
  margin: 20px 0
}

.media-alpha-subtitle {
  font-family: sans-serif;
  font-size: 24px;
}

.question-root-wrapper {
  max-width: 1000px !important;
  width: 100%;
}

.row {
  width: 100%;
}

#ad_unit_placeholder {
  width: 100%;
  max-width: 1200px;
}
</style>